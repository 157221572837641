// Api endpoint & default error message
const apiInfo = {
  endpoint:
    "https://newton.gen-ai-demonstration.publicisgroupe.net/newton/api/chat",
  errorMsg: "Oops! Something went wrong.",
};

// Four cards list
const cardsMockData = {
  leftCards: [
    {
      title:
        "What are the key differences between saving and investing? When should each strategy be employed?",
      isEnable: true,
    },
    {
      title:
        "How can I assess the return on investment when investing in education or skills development?",
      isEnable: true,
    },
  ],
  rightCards: [
    {
      title:
        "What factors should I consider when choosing a homeowners insurance policy?",
      isEnable: true,
    },
    {
      title:
        "How do I determine which health insurance plan best suits my needs?",
      isEnable: true,
    },
  ],
};

const greetingsMsg = {
  text: "Greetings! Ready to explore the world of finance? I'm here to answer your questions and provide guidance. What can I assist you with?",
  isEnable: true,
};

const appDetails = {
  brandName: "Newton",
  mobileHeaderTitle: "Newton",
  contentHeader: "",
  contentSubHeader:
    "Your Insurance and Finance Companion. Ask away your questions in the below chat.",
  messageBox: "",
  sideBarContent: `I'm Newton, your AI-powered search assistant, here to support you
              on your financial path. While I aim for precision, I may
              occasionally make errors. If you have questions concerning
              critical information, it's recommended to verify for accuracy and
              clarity. For additional information on financial guidance, connect
              with a nearby financial advisor at [Newton](https://www.newton.org)`,
};

export { cardsMockData, greetingsMsg, apiInfo, appDetails };
