import { createContext } from "react";
import { v4 as uuid } from "uuid";

export const AppContext = createContext(null);

const AppProvider = ({ children }) => {
  // New unique id
  const unique_id = uuid();
  return (
    <AppContext.Provider value={unique_id}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
