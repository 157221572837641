import SendIcon from "assets/sendIcon.svg";
import { useState } from "react";
import { appDetails } from "config";


const MessageBox = ({ initiateChat, isLoading }) => {
  const [input, setInput] = useState("");
  const inputPlaceholder =
    appDetails?.messageBox || "Ask " + appDetails?.brandName + "...";

  const handleClick = () => {
    if (isLoading) return false;

    if (input?.trim()) {
      initiateChat(input?.trim());
      setInput("");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") handleClick();
  };

  return (
    <div className="flex w-full items-center">
      <div className="overflow-hidden flex flex-row my-6 items-center [&amp;:has(textarea:focus)]:border-token-border-xheavy [&amp;:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-token-border-heavy flex-grow relative border border-token-border-heavy text-white rounded-lg bg-ejgrey  shadow-[0_0_0_2px_rgba(52,53,65,0.95)]">
        <input
          id="prompt-input"
          tabIndex="0"
          placeholder={inputPlaceholder}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
          onKeyDown={handleEnter}
          className="m-0 w-full resize-none border-0 bg-transparent py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 bg-transparent md:h-10 md:pr-12 placeholder-white/50 pl-3 md:pl-4"
        />
        <button
          disabled=""
          className="absolute h-full bg-transparent border-none  right-2 disabled:bg-white disabled:bg-black   text-white p-0.5 border rounded-lg  "
          data-testid="send-button"
          onClick={handleClick}>
          <img src={SendIcon} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default MessageBox;
