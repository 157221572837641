import { useContext } from "react";
import axios from "axios";
import { AppContext } from "services/axios/AppProvider";
import { apiInfo } from "config";

const useAxios = () => {
  const sessionId = useContext(AppContext);

  const callAiBoat = (val, callbackMethod) => {
    (async () => {
      axios
        .post(apiInfo?.endpoint, {
          session_id: sessionId,
          message: val,
        })
        .then((res) => {
          callbackMethod({ type: "success", res: res?.data, inpVal: val });
        })
        .catch((res) => {
          callbackMethod({
            type: "error",
            res: apiInfo?.errorMsg,
            inpVal: val,
          });
        });
    })();
  };

  return { callAiBoat };
};

export default useAxios;
