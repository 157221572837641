import LogoIcon from "assets/logo.svg";
import { appDetails } from "config";
import Markdown from "react-markdown";

const Logo = ({ addClass }) => {
  const title = appDetails?.brandName || "AI Assistant";
  const headerText = appDetails?.contentHeader || "Hi! I am " + title;
  const subHeaderText =
    appDetails?.contentSubHeader || "Simplifying Your Investment Experience";

  const LinkRenderer = (data) => {
    return (
      <a href={data.href} target="_blank" rel="noreferrer">
        {data.children}
      </a>
    );
  };
  return (
    <div
      className={` ${addClass} flex h-full min-h-0 flex-col transition-opacity opacity-100  text-white `}>
      <div className="flex-1 overflow-hidden">
        <div className="relative h-full">
          <div className="flex h-full flex-col items-center justify-center">
            <div className="relative">
              <div className="mb-3">
                <div className=" relative flex items-center justify-center rounded-[1.25rem] w-[60px] h-[60px] md:w-[83px] md:h-[83px]">
                  <div
                    id="branding"
                    className="inline-flex lg:m-0"
                    aria-hidden="false">
                    <div
                      className="flex items-center justify-center"
                      id="anchor-link-8">
                      <img
                        alt={title}
                        className="block"
                        src={LogoIcon}
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-1 mt-3 text-[1.375rem] font-medium">
              {headerText}
            </div>
            <p className="px-5 md:px-16  text-center text-bold text-base font-medium font-sans small:text-[0.875rem] font-bold">
              {subHeaderText}
            </p>
            <p className="left-container px-16 pt-1 text-center text-[0.875rem] font-medium text-[#CDCCCB] font-sans">
              <Markdown components={{ a: LinkRenderer }}>
                {appDetails?.sideBarContent}
              </Markdown>
            </p>
            <p className="flex justify-center items-center flex-col absolute bottom-0 left-container px-20 py-2 text-center text-[0.875rem] font-medium text-[#CDCCCB] font-sans">
              Copyright © 2024 {title}® All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
