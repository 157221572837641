const Card = ({ title, chatInitiate }) => {
  const handleClick = () => {
    chatInitiate(title);
  };
  return (
    <span className="transformOpacity">
      <button
        className="bg-ejgrey px-8 h-16 hover:ring-offset-1 hover:ring-2 hover:bg-ejblack btn relative btn-neutral group w-full rounded-xl px-4 py-3 text-left text-gray-700 dark:text-gray-300 whitespace-normal"
        as="button"
        onClick={handleClick}>
        <div className="flex w-full gap-2 items-center justify-center">
          <div className="flex w-full items-center justify-left">
            <div className="flex flex-col overflow-hidden text-white">
              <div className="text-sm">{title}</div>
            </div>
          </div>
        </div>
      </button>
    </span>
  );
};

export default Card;
