import ChatBoat from "components/organisms/chat-boat";
import { useContext, useState } from "react";
import AppProvider, { AppContext } from "services/axios/AppProvider";
import ChatIcon from "components/atoms/chat-icon";

export default function App() {
  const uuid = useContext(AppContext);
  const [token, setToken] = useState(uuid);
  const [isClose, setClose] = useState(true);
  const handleClose = () => {
    console.log("closeHandler!");
    setClose(!isClose);
  };

  return (
    <div
      className={
        isClose ? "flex justify-end flex-row h-screen items-center" : ""
      }>
      {!isClose ? (
        <AppProvider value={token || uuid}>
          <ChatBoat handleToken={setToken} handleClose={handleClose} />
        </AppProvider>
      ) : (
        <ChatIcon onClose={handleClose} />
      )}
    </div>
  );
}
