import Logo from "components/molecules/logo";
const LeftContainer = () => {
  return (
    <div className="lg:flex left-container bg-ejblack w-[360px] flex-shrink-0 overflow-x-hidden bg-black w-[260px] border-solid border-r-2 border-ejgrey text-white visible ">
      <div className="h-full w-full">
        <div className="flex h-full min-h-0 flex-col">
          <div className="flex h-full min-h-0 flex-col transition-opacity opacity-100">
            <Logo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftContainer;
