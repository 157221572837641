import userIcon from "assets/user-icon.svg";
import ChatLogoIcon from "assets/logo.svg";
import CopyIcon from "assets/copy.svg";
import Markdown from "react-markdown";
import copy from "copy-to-clipboard";

const Chat = ({ type, value, isCopy = true, addClass = "" }) => {
  const copyClipboard = () => {
    copy(value);
  };

  const LinkRenderer = (data) => {
    return (
      <a href={data.href} target="_blank" rel="noreferrer">
        {data.children}
      </a>
    );
  };
  // check chat type
  const isBot = type === "bot"; // bot: ai bot
  const botClass = isBot
    ? "bot bg-botBgColor text-white"
    : "bg-white text-black";
  const chatIcon = isBot ? ChatLogoIcon : userIcon;

  return (
    <div
      id={isBot ? "bot" : "user"}
      className={`w-full text-token-text-primary transition ease-out duration-700 ${addClass}`}
      data-testid="conversations">
      <div className={` w-full py-2 justify-center text-base md:gap-6 m-auto `}>
        <div className="w-full flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 md:max-w-3xl lg:max-w-[40rem]_ xl:max-w-[48rem] group">
          <div className="flex-shrink-0 flex flex-col relative items-end">
            <div>
              <div className="pt-0.5">
                <div className="gizmo-shadow-stroke flex h-12 w-12 items-center justify-center overflow-hidden rounded-full">
                  <div
                    className={`relative rounded-sm text-white flex items-center justify-center w-12 h-12 bg-gray-500 ${
                      isBot ? "bg-white" : ""
                    }`}>
                    <img src={chatIcon} alt={isBot ? "Ai Bot" : "User"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn`}>
            <div className="flex-col gap-1 md:gap-3">
              <div
                className={`flex flex-grow flex-col max-w-full rounded-tl-lg rounded-tr-lg rounded-br-lg ${botClass}`}>
                <div className="markdown min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                  <div className=" text-sm prose w-full break-words dark:prose-invert light p-3">
                    <Markdown components={{ a: LinkRenderer }}>
                      {value}
                    </Markdown>
                  </div>
                </div>
              </div>
              {isCopy ? (
                <div className="mt-1 flex justify-start gap-3 empty:hidden">
                  <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 visible">
                    <span className="" data-state="closed">
                      <button
                        className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible md:group-[.final-completion]:visible"
                        onClick={copyClipboard}>
                        <img
                          className="w-full h-full"
                          src={CopyIcon}
                          alt="copy"
                        />
                      </button>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
