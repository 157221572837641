import Card from "components/atoms/card";
import { cardsMockData } from "config";

const Cards = ({ initiateChat, addClass }) => {
  const handleClick = (val) => {
    initiateChat(val);
  };

  const getLists = (data) => {
    return data.map(
      (val, key) =>
        val?.isEnable &&
        val?.title?.trim() && (
          <Card
            key={key}
            title={val?.title?.trim()}
            chatInitiate={() => handleClick(val.title)}
          />
        )
    );
  };

  const leftCardList = getLists(cardsMockData.leftCards);
  const rightCardList = getLists(cardsMockData.rightCards);
  return (
    <div>
      <div
        className={`h-full flex ml-1 md:w-full md:m-auto md:mb-4 gap-0 md:gap-2 justify-center ${addClass}`}>
        <div className="grow">
          <div className="absolute_ bottom-full left-0 mb-4 flex w-full grow gap-2 px-1 pb-1 sm:px-2 sm:pb-0 md:static md:mb-0 md:max-w-none">
            <div className="grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2 items-end">
              {leftCardList}
              {rightCardList}
              {/* <div className="flex flex-col gap-2">{leftCardList}</div>
              <div className="flex flex-col gap-2">{rightCardList}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
