import React, { useEffect, useRef, useState } from "react";
import useAxios from "hooks/useAxios";
import { v4 as uuid } from "uuid";

import Logo from "components/molecules/logo";
import Cards from "components/molecules/cards";
import ChatBox from "components/molecules/message-box";
import LeftContainer from "components/molecules/left-container";
import Chats from "components/molecules/chats";
import Chat from "components/atoms/chat";
import { greetingsMsg } from "config";
import Header from "components/molecules/header";

const ChatBot = ({ handleToken, handleClose }) => {
  const [isChatStart, setChatStart] = useState(false);
  const [messages, setMessage] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const chatsRef = useRef(null);
  const chatListRef = useRef(null);
  const { callAiBoat } = useAxios();

  // const theme = useContext(ThemeContext);
  const reloadHandle = () => {
    if (isChatStart) {
      const unique_id = uuid();
      handleToken(unique_id);
      setChatStart(false);
      setMessage([]);
      setLoader(false);
    }
  };

  const updateMessage = ({ inpVal, chatVal }) => {
    const msgList = [];
    if (inpVal)
      msgList.push({
        text: inpVal,
        isBot: false,
      });
    if (chatVal) {
      msgList.push({
        text: chatVal,
        isBot: true,
      });
    }

    return msgList;
  };

  const handleAPIhook = ({ type, res, inpVal }) => {
    setLoader(false);
    const chatList = updateMessage({
      inpVal: inpVal,
      chatVal: res,
    });
    setMessage([...messages, ...chatList]);
  };

  const aiBotHandle = (val) => {
    setLoader(true);
    callAiBoat(val, handleAPIhook);
  };

  const initiateChat = (val) => {
    aiBotHandle(val);
    setChatStart(true);
    const chatList = updateMessage({ inpVal: val });
    setMessage([...messages, ...chatList]);
  };

  useEffect(() => {
    if (window && messages.length) {
      const target = chatsRef?.current;

      // Scroll if latest content is hidding
      const chatList = chatListRef?.current;

      // Get the last child element
      const targetLastElement = chatList?.lastElementChild;

      // Get the height of last element
      const scrollOffset =
        targetLastElement?.offsetHeight - (target?.offsetHeight - 25);

      // if scrollOffset is available & greater than 0
      if (scrollOffset > 0) {
        const offset = -scrollOffset;
        target?.scrollTo(0, offset);
      } else {
        // Scroll down when user enter value
        const target = chatsRef?.current;
        target.scrollTo(0, 0);
      }
    }
  }, [messages]);

  return (
    <div className="ejblack relative bg-ejwhite h-lvh z-0 flex w-full overflow-hidden">
      <LeftContainer />
      <div className="bg-ejblack relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <Header
          reloadHandle={reloadHandle}
          handleClose={handleClose}
          addClass="logo-container"
        />
        <main className="relative h-full w-full flex-1 overflow-auto">
          <div role="presentation" className="flex h-full flex-col">
            <div className="flex-1 overflow-hidden">
              <div className="flex h-full min-h-0 flex-col">
                {!isChatStart ? <Logo addClass="logo-container" /> : null}
                {isChatStart ? (
                  <Chats
                    messages={messages}
                    chatsRef={chatsRef}
                    chatListRef={chatListRef}
                  />
                ) : null}
              </div>
            </div>
            <div className="w-full dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
              <div className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
                <div className="relative flex h-full flex-1 items-stretch flex-col">
                  {!isChatStart ? (
                    <>
                      {greetingsMsg?.isEnable ? (
                        <Chat
                          value={greetingsMsg?.text?.trim()}
                          type="bot"
                          isCopy={false}
                          addClass={"py-9 left-container"}
                        />
                      ) : null}
                      <Cards initiateChat={initiateChat} addClass={"pb-12"} />
                    </>
                  ) : null}
                  <div>
                    {isLoading ? (
                      <div className="loader h-9 flex flex-row items-center justify-center h-full w-full gap-3 py-2">
                        <span className="sr-only">Loading...</span>
                        <div className="h-3 w-3 bg-loaderColor rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                        <div className="h-3 w-3 bg-loaderColor rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                        <div className="h-3 w-3 bg-loaderColor rounded-full animate-bounce"></div>
                      </div>
                    ) : null}

                    <ChatBox
                      initiateChat={initiateChat}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default React.memo(ChatBot);
