import Chat from "components/atoms/chat";

const Chats = ({ messages, chatsRef, chatListRef }) => {
  return (
    <div className="flex h-full min-h-0 flex-col transition-opacity opacity-100  text-white flex-col-reverse ">
      <div
        ref={chatsRef}
        className="chats px-3 overflow-hidden overflow-y-scroll scroll-smooth">
        <div
          ref={chatListRef}
          className="chat-list lg:mx-auto lg:max-w-2xl xl:max-w-3xl  chat-width">
          {messages.map((val, key) => (
            <Chat
              key={key}
              type={val?.isBot ? "bot" : "user"}
              value={val?.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chats;
