import ReloadIcon from "assets/refresh-icon.svg";
import LogoIcon from "assets/headerLogo.svg";
import Minimize from "assets/minimize.svg";
// import HeaderGradient from "assets/HeaderGradient.svg";
import { appDetails } from "config";

const Header = ({
  addClass = "",
  reloadHandle,
  hideChatHandle,
  handleClose,
}) => {
  const title = appDetails?.brandName || "AI Assistant";
  return (
    <>
      <header
        className={`bg-chatColor flex flex-row justify-between items-center h-10 pl-4 ${addClass}`}>
        <p className="flex flex-row gap-1 text-lg text-ejwhite font-sans ">
          <img
            className="mb-2"
            width={25}
            height={25}
            src={LogoIcon}
            alt={title}
          />
          {title}
        </p>
        <div>
          <button className="p-3 hover:cursor-pointer" onClick={reloadHandle}>
            <img className=" w-5 h-5 color" src={ReloadIcon} alt="reset" />
          </button>
          <button className="p-3 hover:cursor-pointer" onClick={handleClose}>
            <img className=" w-5 h-5 color" src={Minimize} alt="minimize" />
          </button>
        </div>
      </header>
      {/* <div class="h-14 bg-gradient-to-t from-ejblack to-transparent">
        <img src={HeaderGradient} alt="header gradient" />
      </div> */}
    </>
  );
};

export default Header;
