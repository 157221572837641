import RactangleIcon from "assets/ractangleIcon.svg";

export default function ChatIcon({ onClose }) {
  const handleClick = () => {
    onClose();
  };

  return (
    <div
      onClick={handleClick}
      className="chat-icon w-20 h-16 mb-36 flex items-center cursor-pointer justify-center rounded-l-[1.25rem]">
      <img src={RactangleIcon} className="ml-2 mb-2 w-11 h-10" alt="" />
    </div>
  );
}
